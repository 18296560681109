::-webkit-scrollbar {
  width: 0rem;
  opacity: 0;
  transition: opacity 1s;
}

*:hover::-webkit-scrollbar {
  opacity: 1;
  width: 1rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.wrapper {
  max-width: 100vw;
  overflow: auto;
  margin: auto;
  height: 100%;
}

.loading {
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1000000;
}

.loading + .inner-wrapper {
  filter: blur(0.2rem);
  pointer-events: none;
  cursor: default;
}

body {
  background: #fff !important;
  font-size: 1.6rem;
}

a,
a:hover,
a:visited,
a:link {
  text-decoration: none;
}

.brand-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    margin-right: 1rem;
  }

  .logo-text {
    text-decoration: none;
    font-size: 1.2rem;
    color: #555;
  }
}

.bb-2 {
  border-bottom: 0.2rem solid #555;
}

:root {
  font-size: 62.5%;
}

*,
ul {
  padding: 0;
  margin: 0;
}

.nostyle,
.nostyle:focus,
.nostyle:active,
.nostyle:visited,
.nostyle:hover,
.nostyle:link {
  text-decoration: none;
  color: unset;
}

p.text-muted {
  font-size: 13px;
}

.color-green {
  color: green !important;
}

.color-red {
  color: #fd625e !important;
}

.lg-icon {
  font-size: 50px;
}

.dropdown-menu {
  font-size: 1.2rem;
  .dropdown-header {
    font-size: 1.5rem;
  }
  .dropdown-item {
    font-size: 1.2rem;
  }
}

.error-class,
.error-class:focus {
  outline: 0.3rem solid lighten(red, 30%) !important;
}

.noerror-class,
.noerror-class:focus {
  outline: 0.3rem solid lighten(green, 60%) !important;
}

button[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
}

.logo-text {
  font-family: math;
  color: #000000;
  font-size: 2.5rem;
  font-weight: bolder;
}
.highlighted-text {
  color: #00873e;
}
