@media screen and (max-width: 1500px) {
  :root {
    font-size: 50%;
  }
}

@media screen and (max-width: 1200px) {
  :root {
    font-size: 45%;
  }
}

@media screen and (max-width: 1000px) {
  :root {
    font-size: 40%;
  }
}

@media screen and (max-width: 800px) {
  .header {
    background: url("../../assets/svgs/Snow.svg"),
      url("../../assets/images/bg1.png") !important;
    .tagline {
      grid-template-columns: 1fr !important;
      .tagline-right {
        display: none;
      }

      .tagline-left {
        max-width: 100% !important;
        grid-gap: 0.5rem;
        text-align: center;
        margin: auto;
        * {
          text-align: center;
          margin: auto;
        }
      }
    }

    .title-bar {
      .nav,
      .signing-in {
        display: none !important;
      }
    }
  }

  .get-your-gift {
    grid-template-columns: 1fr !important;
    grid-gap: 4rem !important;

    .right-content {
      text-align: center;
      margin: auto;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.5rem;
      * {
        text-align: center;
        margin: auto;
      }
    }
  }

  .join-the-party {
    grid-template-columns: 1fr !important;
    grid-gap: 4rem !important;
    .right-img {
      grid-row-start: 1;
    }

    .left-content {
      text-align: center;
      margin: auto;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.5rem;
      * {
        text-align: center;
        margin: auto;
      }
    }
  }

  .get-items-wrapper .get-items .item-list {
    grid-template-columns: 1fr !important;

    .item {
      margin: auto;
    }
  }

  .live-event {
    grid-template-columns: 1fr !important;
    grid-gap: 7rem !important;

    .right-content {
      align-items: center !important;
      justify-content: center !important;
      text-align: center;
      margin: auto;
      * {
        text-align: center;
        margin: auto;
      }
    }
  }

  .contact-to-join {
    grid-template-columns: 1fr !important;
    .right-body {
      display: none;
    }

    .left-body {
      text-align: center;
      justify-content: center;
      margin: auto;
      & > * {
        text-align: center;
        margin: auto;
      }
    }
  }

  .footer-parent {
    .footer-bar {
      text-align: center;
      justify-content: center;
      margin: auto;
      * {
        text-align: center;
        margin: auto;
      }
      .text-block .socials {
        grid-template-columns: 1fr !important;
      }
    }
  }

  .sign-up,
  .login,
  .vaidate-email,
  .reset-pswd,
  .reset-pwd-action {
    .top-left,
    .top-right,
    .bottom-left,
    .bottom-right {
      display: none;
    }
  }
}
