.header {
  display: flex;
  background: url("../../assets/svgs/Snow.svg"),
    url("../../assets/images/bg1.png"), url("../../assets/images/bg2.png");
  background-position: top, top, bottom;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 100%, 100%, 100%;
  border-radius: 0px 0px 2rem 2rem;
  flex-direction: column;

  .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 10rem;

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 10rem;
      }

      .logo-text {
        font-family: math;
        color: #000000;
        font-size: 2.5rem;
        font-weight: bolder;
      }
      .highlighted-text {
        color: #00873e;
      }
    }

    .nav {
      .nav-list {
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        align-items: center;

        li {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 2.3rem;
          color: #000000;
          margin: 4rem;
        }
        .highlighted {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 2.3rem;
          color: #00873e;
        }
      }
    }

    .signing-in {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .in {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.3rem;
        color: #000000;
        margin: 3rem;
      }
      .up {
        background: #00873e;
        border-radius: 0.8rem;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.3rem;
        color: #ffffff;
      }
    }
  }

  .tagline {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 10rem;
    grid-gap: 2rem;

    .tagline-left {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-content: center;
      max-width: 50rem;

      h1 {
        font-family: "Yeseva One";
        font-style: normal;
        font-weight: 400;
        font-size: 7.5rem;
        line-height: 8.7rem;
        color: #333333;
      }

      p {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 2.2rem;
        line-height: 3.4rem;
        color: #000000;
      }

      .action-btn {
        padding: 2rem 2.4rem;
        width: fit-content;
        letter-spacing: 0.1rem;
        cursor: pointer;
        transition: top 0.5s;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 2.9rem;
        color: #ffffff;
        background: #00873e;
        border-radius: 0.8rem;
      }

      .action-btn:hover {
        background: darken(#71a894, 20%);
        position: relative;
        top: -0.1rem;
      }
    }

    .tagline-right {
      position: relative;
      .circle {
        // position: absolute;
        height: 35rem;
        width: 35rem;
        max-width: 90vw;
        max-height: 90vw;
        border-radius: 50%;
        margin: auto;
        border: 5px solid white;
        box-shadow: 0px 0px 3rem 3rem white;
      }
      .santa-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-40%, -50%);
        img {
          transform: scale(1.5);
        }
      }
      .bell-img {
        position: absolute;
        right: 15%;
        top: 0;
        img {
          width: 10rem;
        }
      }
      .BgTree-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-65%, -56%);
        img {
          transform: scale(1.2) rotate(-37deg);
        }
      }
    }
  }
}
