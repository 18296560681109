/* Core CSS required for Ionic components to work properly */
@import "~@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/react/css/normalize.css";
@import "~@ionic/react/css/structure.css";
@import "~@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/react/css/padding.css";
@import "~@ionic/react/css/float-elements.css";
@import "~@ionic/react/css/text-alignment.css";
@import "~@ionic/react/css/text-transformation.css";
@import "~@ionic/react/css/flex-utils.css";
@import "~@ionic/react/css/display.css";

/* Font awesome https://fontawesome.com/search?m=free */
@import "~@fortawesome/fontawesome-free/css/all.min.css";
/* React Bootstrap */
@import "~bootstrap/scss/bootstrap.scss";
/* React Toastify */
@import "~react-toastify/dist/ReactToastify.min.css";

/* Theme variables */
@import "../theme/variables";

@import "./main";
