.vaidate-email {
  width: 100vw;
  height: 100vh;
  background: black;
  position: relative;
  color: white;

  .top-left {
    position: absolute;
    left: 0rem;
    top: 0rem;
  }
  .top-right {
    position: absolute;
    right: 0rem;
    top: 0rem;
  }
  .bottom-left {
    position: absolute;
    left: 0rem;
    bottom: 0rem;
  }
  .bottom-right {
    position: absolute;
    right: 0rem;
    bottom: 0rem;
  }
  .page-center {
    padding: 1rem;
    width: 100vw;
    max-width: 45rem;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-self: center;

    #validation-message {
      min-width: 40rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .card {
      background-color: black;
      color: white;
    }

    .primary-color {
      color: #00873e;
    }
  }
}
