.footer-parent {
  .footer-bar {
    display: grid;
    grid-template-columns: 1fr;

    .text-block {
      padding: 5rem 10rem;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      align-self: center;

      h1 {
        font-family: "Yeseva One";
        font-style: normal;
        font-weight: 400;
        font-size: 3.8rem;
        color: #000000;
      }
      .socials {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        p {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 1.8rem;
          color: #000000;
        }

        .social-logo {
          img {
            height: 4rem;
            cursor: pointer;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
