.pageWrapper {
  min-height: 100vh;
  background-color: white;
}
.contentArea {
  flex-grow: 1;
  width: calc(100vw - 260px);
}
.card {
  box-shadow: $card-box-shadow;
  margin-bottom: 30px;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }
}
.button-group .btn {
  margin: 3px;
}

.main-content,
.nav-content {
  max-height: calc(100vh - 70px);
  overflow: auto;
}

.height-60px {
  height: 60px !important;
}
