.reset-pwd-action {
  width: 100vw;
  height: 100vh;
  background: black;
  position: relative;

  .top-left {
    position: absolute;
    left: 0rem;
    top: 0rem;
  }
  .top-right {
    position: absolute;
    right: 0rem;
    top: 0rem;
  }
  .bottom-left {
    position: absolute;
    left: 0rem;
    bottom: 0rem;
  }
  .bottom-right {
    position: absolute;
    right: 0rem;
    bottom: 0rem;
  }
  .page-center {
    padding: 1rem;
    width: 100vw;
    max-width: 45rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-self: center;

    h1 {
      font-family: "Itim";
      font-style: normal;
      font-weight: 400;
      font-size: 3.6rem;
      line-height: 4.4rem;
      color: #ffffff;
      align-self: center;
    }

    .user-name {
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: flex-start;
      padding: 0.75rem;
      position: relative;
      width: 100%;
    }

    .lbl {
      font-family: "Itim";
      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.18rem;
      color: #ffffff;
      margin-bottom: 0.23rem;
    }

    .pswd {
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: flex-start;
      padding: 0.75rem;
      width: 100%;
    }

    .cnfm-pswd {
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: flex-start;
      padding: 0.75rem;
      width: 100%;
    }

    .input-icon {
      position: relative;
      width: 100%;
      .input-ctrl {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-indent: 5rem;
        padding: 0.98rem 0rem 0.98rem 0rem;
        gap: 1.13rem;
        background: #fff;
        border: 0.15rem solid #000000;
        border-radius: 1.13rem;
        font-family: "Itim";
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.18rem;
        color: #000;
        width: 100%;
      }

      .set-indent {
        text-indent: 2.5rem;
      }

      .input-ctrl:focus {
        outline: none;
        border: 0.15rem solid rgba(255, 255, 255, 1);
      }

      .user-icon {
        position: absolute;
        left: 0;
        top: 0;
        padding-right: 0.8rem;
        margin: 0.75rem;
        border-right: 0.23rem solid #000;
        margin-right: 0rem;
      }
    }

    .reset-btn {
      display: flex;
      align-self: center;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      width: 100%;

      .reset-btn {
        background: #00873e;
        border-radius: 1.13rem;
        font-family: "Itim";
        font-style: normal;
        font-weight: 400;
        font-size: 2.33rem;
        color: #ffffff;
        min-width: fit-content;
        width: 70%;
        margin-top: 3rem;
        padding: 1rem;
      }
    }
  }

  .login-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: center;
    padding-top: 1.5rem;

    .in-case {
      font-family: "Itim";
      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.18rem;
      color: #ffffff;
    }
    .highlighted {
      font-family: "Itim";
      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.18rem;
      color: #00873e;
      margin-left: 0.6rem;
    }
  }
}
