.join-the-party {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  padding: 10rem 10rem 5rem 10rem;

  .left-content {
    .content-heading {
      font-family: "Yeseva One";
      font-style: normal;
      font-weight: 400;
      font-size: 6.5rem;
      line-height: 7.5rem;
      color: #000000;
    }

    .content-desc {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 3.4rem;
      color: #000000;
    }

    .join-party-btn {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 2.9rem;
      color: #ffffff;
      background: #00873e;
      border-radius: 0.8rem;
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  .right-img {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      transform: scale(1.2);
      width: 35rem;
      max-width: 90vw;
    }
  }
}
