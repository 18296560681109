.custom-table-outer {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  background-color: #fff;
  position: relative;

  &:hover .custom-table-pagination {
    opacity: 1 !important;
  }

  .d-none {
    display: none !important;
  }

  .widget-heading {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }

  .download-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    color: darken(#04a9f5, 8.5%);
    cursor: pointer;
  }

  .menu-list-wrapper {
    position: absolute;
    z-index: 1000;
    padding-top: 0px;
    pointer-events: auto;
    right: 10px;
    top: 35px;

    ul {
      list-style: none;
      margin: 0px;
      padding: 5px 0px;
      box-shadow: rgb(136, 136, 136) 3px 3px 10px;
      border: 1px solid rgb(153, 153, 153);
      background: rgb(255, 255, 255);

      li {
        cursor: pointer;
        padding: 0.5em 1em;
        color: rgb(51, 51, 51);
        background: none;
        font-size: 11px;
        transition: background 250ms ease 0s, color 250ms ease 0s;
        display: flex;
        align-items: center;

        &.menu-item-heading {
          cursor: default;
          pointer-events: none;
          padding: 0;
          padding-left: 5px;
          font-weight: 700;
        }
      }

      .menu-item-content {
        max-height: 200px;
        overflow: auto;
        max-width: 300px;
      }
    }
  }

  .menu-icon {
    position: absolute;
    right: 8px;
    top: 10px;
    color: #666666;
    font-size: 17px;
    cursor: pointer;
    padding: 3px 4px;
    border-radius: 2px;
    font-weight: 600;
  }

  .menu-icon:hover {
    background-color: #e6e6e6;
  }

  .filter-refresh {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;
    color: darken(#04a9f5, 8.5%);
    cursor: pointer;
  }

  .cutom-table-main {
    min-height: 400px;
    max-height: 75vh;
    overflow: auto;
    background-color: #fff;

    &.on-full-screen {
      max-height: 90vh !important;
    }
    .custom-table-wrapper {
      min-width: 100%;
      width: fit-content;
      .visibility-hidden {
        visibility: hidden;
      }

      .loading-spiner {
        position: relative;
        top: 10;
        left: 10;
      }

      .table {
        border-collapse: unset;

        p {
          margin: 0;
        }
      }

      .table td,
      .table th {
        padding: 7px 12px;
      }

      .table td {
        border-color: #fbfbfb;
      }

      .table th {
        color: #898989;
      }

      .custom-table {
        .total-row {
          font-weight: 700;

          td:nth-child(1) {
            z-index: 100 !important;
          }

          td {
            position: sticky;
            bottom: 0;
            z-index: 99;
            background-color: #f4faff;
            color: #898989;
            border: 1px solid #cecece;
          }
          td::before {
            content: " ";
            position: absolute;
            left: 0;
            top: 0;
            height: 2px;
            width: 100%;
            background: lighten(#455a64, 50%);
          }
        }
        .parent-header {
          text-align: center;
          height: 34px;
        }
        .parent-header::before {
          background: transparent !important;
        }
        .sticky-headers > th {
          position: sticky;
          top: 2px;
          z-index: 101;
          transform: translateY(-2.1px);
        }

        .sticky-headers > th::before {
          content: " ";
          position: absolute;
          left: 0;
          bottom: 0px;
          height: 2px;
          width: 100%;
          background: lighten(#455a64, 50%);
        }

        .sticky-subheaders > th {
          position: sticky;
          top: 39px;
          z-index: 101;
          transform: translateY(-2.1px) !important;
        }

        .sticky-subheaders > th::before {
          content: " ";
          position: absolute;
          left: 0px;
          bottom: 0px;
          height: 2px;
          width: 100%;
          background: lighten(#455a64, 50%);
        }

        &.sticky-row {
          tr:nth-child(1) > th:nth-child(1):not(.td-grouped) {
            position: sticky;
            left: 2px;
            z-index: 999 !important;
            border: 1px solid #cecece;
            transform: translate(-2.1px, -2.1px);
          }
          // tr.sticky-subheaders > th:nth-child(1):not(.td-grouped) {
          //     position: sticky;
          //     left: 0px;
          //     z-index: 998 !important;
          // }
          tr > td:nth-child(1):not(.td-grouped) {
            position: sticky;
            left: 2px;
            // z-index: 998 !important;
            z-index: 1;
            background-color: #f4faff;
            border: 1px solid #cecece;
            transform: translateX(-2.1px);
          }

          tr:nth-child(1)
            > th:nth-child(1):not(.td-grouped):not(.primary-col-data):not(.primary-col-data-alt):after,
          tr
            > td:nth-child(1):not(.td-grouped):not(.primary-col-data):not(.primary-col-data-alt)::after {
            content: " ";
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 3px;
            background: lighten(#455a64, 50%);
            transform: translateX(1px);
          }
        }

        &.sticky-last-col-row {
          tr:nth-child(1) > th:last-child {
            position: sticky;
            right: 0px;
            z-index: 999 !important;
            border: 1px solid #cecece;
            transform: translate(-2.1px, -2.1px);
          }

          tr > td:last-child {
            position: sticky;
            right: 0px;
            z-index: 1;
            background-color: #f4faff;
            border: 1px solid #cecece;
            transform: translateX(-2.1px);
          }

          tr:nth-child(1) > th:last-child:after,
          tr > td:last-child::after {
            content: " ";
            position: absolute;
            left: -1px;
            bottom: 0;
            height: 100%;
            width: 3px;
            background: lighten(#455a64, 50%);
            transform: translateX(1px);
          }

          .total-row {
            td:last-child {
              z-index: 100 !important;
            }
          }
        }

        tr > td {
          color: #000;
          vertical-align: middle;

          &.primary-col-data,
          &.primary-col-data-alt {
            background-color: #bbb !important;
            color: white;
            letter-spacing: 2.5px;
          }

          .content-ul > li {
            list-style-type: circle;
          }
          sub {
            color: darken(#04a9f5, 8.5%);
            white-space: nowrap;
          }

          sub:hover {
            cursor: pointer !important;
            text-decoration: underline;
          }
        }

        tr > th {
          .column-header-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .column-header {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .multilineTitle {
              padding-left: 10px;
            }
          }

          &:hover > * {
            position: relative;
          }

          background: #f4faff !important;
          vertical-align: middle;
          color: #898989;
          font-size: 12px;
          letter-spacing: 1.5px;
          border: 1px solid #cecece;
          .dd-filter-items {
            max-height: 200px;
            overflow: auto;
            margin-left: 9px;
          }

          .dropdown-menu {
            width: auto;
            min-width: 300px;
            padding-bottom: 10px;
          }

          .sortable-icons {
            i {
              display: inline-block;
              cursor: pointer;
              transition: all 300ms;
            }

            i.sorted-asc {
              padding: 0px 3px;
              width: fit-content;
              opacity: 1;
              color: lighten(#1434a4, 15%);
            }

            i.sorted-desc {
              padding: 0px 3px;
              width: fit-content;
              opacity: 1;
              color: lighten(#1434a4, 15%);
            }

            i:hover {
              opacity: 1;
              color: lighten(#1434a4, 10%);
            }
            i:last-child {
              margin-left: -6px;
            }
          }

          .column-header:hover .sortable-icons > i {
            padding: 0;
            width: fit-content;
          }

          .filterable-icon {
            margin-left: 6px;

            i {
              cursor: pointer;
            }

            i:hover {
              color: lighten(#1434a4, 10%);
            }

            .filtered {
              color: lighten(#1434a4, 10%);
            }
          }

          .dd-container {
            display: inline-block;

            .dd-filter {
              text-decoration: none;
              color: #898989;
              &::after {
                display: none;
              }
              padding-top: 0;
              padding-bottom: 0;
              margin: 0;
            }

            ul {
              margin: 0;
            }
          }
        }

        .td-expendable {
          max-width: 30vw;
          white-space: break-spaces;
        }

        .td-image {
          padding: 1px 0.45rem;
          text-align: center;
        }

        .td-image-header {
          padding: 7px 40px;
          text-align: center;

          .column-header-wrapper {
            justify-content: center !important;
          }
        }

        .td-json {
          position: relative;

          ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: transparent;
          }

          ::-webkit-scrollbar-thumb {
            height: 6px;
            border: 0px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-radius: 7px;
            background-color: #999;
          }
          .copy-btn {
            color: darken(#04a9f5, 8.5%);
            cursor: pointer;
            position: absolute;
            right: 15px;
            top: 5px;
            z-index: 100;
          }
          pre {
            max-height: 200px;
            max-width: 500px;
            position: relative;
          }
        }

        .td-anchor {
          text-align: center;
        }
        .td-img-href {
          color: #1434a4 !important;
          width: 35px;
          height: 35px;
          position: relative;
          top: 10px;
          padding: 0px 8px;
        }
        .td-img {
          object-fit: cover;
          width: 35px;
          border-radius: 3px;
          cursor: pointer;

          &.img-hoverable:hover {
            cursor: pointer;
            position: relative;
            transform: scale(1.5);
            transition: all 2s;
          }
        }

        .td-href {
          color: #1434a4 !important;
          cursor: pointer;
        }

        .td-href-fade {
          color: #7e96ee !important;
          cursor: pointer;
        }

        .dd-filter-actions {
          float: right;
        }

        .dd-filter-text {
          min-width: 200px;
          max-width: 350px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block;
          padding-left: 5px;
          padding-bottom: 3px;
        }

        .dd-filter-item {
          display: flex;
          align-items: flex-start;
          min-width: 250px;
          input[type="checkbox"] {
            position: relative;
            top: 7px;
          }
          .form-control {
            width: 95%;
            padding: 5px 10px;
          }

          .form-control:focus {
            outline: none;
            box-shadow: none;
          }
        }
        .dropdown-menu > li.active,
        .dropdown-menu > li:active,
        .dropdown-menu > li:focus,
        .dropdown-menu > li:hover {
          background: transparent;
        }

        .btn-outline-dark:hover {
          background-color: #fff;
          color: #37474f;
          border-color: #37474f;
        }
      }
      .footer-components {
        float: left;
        position: sticky;
        bottom: 0;
        left: 0;
        z-index: 98;
        background-color: white;
        box-shadow: 1px 2px 13px #bbb;
        border-radius: 4px;
      }
    }
  }
  .display-none {
    display: none !important;
  }

  .filter-box-input {
    padding: 5px 10px;
  }

  .filter-box-input:focus {
    outline: none;
    box-shadow: none;
  }

  .fade-text {
    color: #bbb;
  }

  .range-min-max {
    cursor: pointer;
    color: darken(#04a9f5, 8.5%);
  }

  tbody tr {
    background-color: #f2f3fa !important;
  }

  .custom-table-pagination {
    opacity: 0;
    float: right;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1000;
    transition: all 1s;
    margin: 5px;
    .page-item .page-link {
      background-color: #bec4d5 !important;
      color: white;
    }

    .page-item .page-link:focus {
      box-shadow: none;
    }

    .page-item.active .page-link {
      background-color: lighten(#1dc4e9, 40%) !important;
      color: black !important;
      border-color: lighten(#1dc4e9, 40%) !important;
    }

    .page-item.disabled .page-link {
      background-color: lighten(#3f4d67, 10%);
      color: white;
    }
    .page-item.active.disabled .page-link {
      background-color: #1dc4e9;
      color: white;
    }
  }
  .pagination-display-none {
    display: none;
  }
}
.image-slider {
  .modal-content {
    background: transparent;
    border: none;
    box-shadow: none;

    .carousel-modal.modal-body {
      // height: 40vw !important;
      max-height: 40vw !important;
      width: 100%;
      padding: 0px !important;
      // width: fit-content;
      .carousel.slide {
        // height: 40vw !important;
        max-height: 40vw !important;
        // width: fit-content;
        .carousel-inner {
          // height: 40vw !important;
          max-height: 40vw !important;
          // width: fit-content;
          .carousel-item {
            // height: 40vw !important;
            max-height: 40vw !important;
            margin: auto;
            // width: fit-content;
            img {
              max-height: 40vw !important;
              object-fit: cover;
              margin: auto;
            }
            video {
              object-fit: cover;
              width: inherit;
              // height: 40vw !important;
            }
          }
        }

        .carousel-control-next {
          height: 50px;
          margin: auto;
          border-radius: 50%;
          width: 50px;
          background: #aaa;
        }

        .carousel-control-prev {
          height: 50px;
          margin: auto;
          border-radius: 50%;
          width: 50px;
          background: #aaa;
        }
      }
    }
  }
}
.col-p {
  &-red {
    background: lighten($color: green, $amount: 3%);
    color: white;
  }
  &-orange {
    background: lighten($color: green, $amount: 6%);
    color: white;
  }
  &-lightgreen {
    background: lighten($color: green, $amount: 9%);
    color: white;
  }
  &-green {
    background: lighten($color: green, $amount: 12%);
    color: white;
  }
}

.col-img-thumbnail {
  width: 20px;
  height: 20px;
  position: relative;
}

.col-img-thumbnail img {
  width: 100%;
  border-radius: 3px;
}

.col-img-thumbnail img:hover {
  transform: scale(1.4);
}

.singleImage {
  .carousel-indicators,
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
}

.cms-container {
  position: relative;
  ul {
    margin: 0 !important;
  }
  .cms-filter {
    border-bottom: 3px solid #038cca;
    margin: 0 !important;
    background: white;
    border-radius: 5px 5px 0px 0px;
    font-family: "Roboto";
    font-size: 13px;
    font-weight: 400;
    &.dropdown-toggle::after {
      margin-left: 10px;
    }
    .cms-badge {
      position: relative;
      top: -10px;
      color: white;
      background: #4171a3;
    }
  }
  .cms-menu {
    padding: 10px 20px;
    min-width: 350px;
    .cms-filter-items {
      .cms-filter-item {
        .cms-filter-box-input {
          padding: 5px 10px;
        }
        input[type="checkbox"] {
          margin-right: 8px;
        }
      }
    }
  }

  .cms-menu-lg {
    padding: 10px 20px;
    min-width: 400px;
    .cms-filter-items {
      .cms-filter-item {
        .cms-filter-box-input {
          padding: 5px 10px;
        }
        input[type="checkbox"] {
          margin-right: 8px;
        }
      }
    }
  }
  .cms-display-none {
    display: none;
  }
  .cms-menu-items {
    max-height: 50vh;
    overflow: auto;
  }
}

.widget-footer {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .feedback-options {
    padding: 5px 1px 5px 8px;
    font-size: 18px;
    border-radius: 4px;
  }
  .footer-text {
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    text-align: end;
    code {
      font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
        sans-serif;
      color: black;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.link-color {
  color: #1434a4 !important;
}

.hover-blue:hover {
  color: #1434a4 !important;
}

.feedback-title-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.w-500 {
  width: 500px;
}
.w-300 {
  width: 300px;
}
.w-150 {
  width: 150px;
}
.w-200 {
  width: 200px;
}

.fw-right {
  font-weight: lighter;
}

.conversation-box {
  background-color: #fff;
  max-width: 600px;
  margin: auto;
}

.h-35vh {
  height: 35vh;
}

.h-45vh {
  height: 45vh;
}

.box-shadow-title {
  box-shadow: 1px 1px 2px #548ae0;
  padding: 10px;
  background: #1434a4;
  color: white;
  height: 41px;
}

.feedback-thread-action {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 31px;
}

.conversation-box {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  position: relative;
  border-radius: 6px;
}

.send-icon {
  padding: 8px 12px;
  background-color: #273338;
  color: #fff;
  border-radius: 6px;
}

.send-icon-light {
  padding: 8px 12px;
  background-color: lighten(#273338, 15%);
  color: #fff;
  border-radius: 6px;
}

.conv-chatbox {
  height: calc(35vh - 72px);
}

.conv-chatbox-admin {
  height: calc(45vh - 72px);
}

.align-self-right {
  align-self: flex-end;
}

.paper-card {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  border-radius: 4px;
  overflow: auto;
  position: relative;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  p {
    font-size: 1rem;
  }
}

.custom-pannel {
  &.bg-white {
    background: #fff;
  }

  &.bg-offwhite {
    background: #eee;
  }
  padding: 20px;
  padding-bottom: 60px;
  position: relative;
  border-radius: 4px;
  box-shadow: 3px 3px 12px 1px #ccc;
  &__header {
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .custom-pannel-heading {
      font-size: 24px;
      font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
        sans-serif;
      padding: 5px 10px;
      color: #000;
      line-height: 1.5;
      letter-spacing: 1.5px;
      text-align: center;
      margin: 0;
    }
  }

  &__body {
    min-height: 400px;
  }

  &.height-auto &__body {
    min-height: auto !important;
  }

  &__footer {
    .widget-footer {
      left: 0px;
    }
  }

  &.bg-offwhite &__footer {
    .widget-footer {
      background-color: #eee;
    }
  }
}

.bg-alt1 {
  background: #f1ffff;
  border-left: 5px solid #50ffff;
}

.bg-alt2 {
  background: #fff1ff;
  border-left: 5px solid #ff50ff;
}

.bg-alt3 {
  background: #fffff1;
  border-left: 5px solid #ffff50;
}

.bg-alt4 {
  background: rgb(180, 170, 240);
  border-left: 4px solid rgb(0, 50, 200);
}

.bg-alt5 {
  background: antiquewhite;
  border-left: 4px solid orange;
}

.pannel-card-abs {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px 10px;
}

.pannel-header-component {
  padding: 10px;
  text-align: center;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  color: #000;
  border-radius: 0px 4px 4px 0px;

  * {
    margin: 0;
  }

  &__title {
    font-weight: bold;
    font-family: "roboto";
    font-size: 1.1rem;
    letter-spacing: 1.1px;
  }

  &__value {
    font-family: "roboto";
    font-size: 0.9rem;
  }
}

.custom-avatar-editor {
  display: flex;
  justify-content: center;
  color: #000;
  .avatar-editor-box {
    text-align: center;
  }
  label {
    font-weight: 600;
  }
}

.blog-assets {
  img {
    height: 50px;
    width: 50px;
    object-fit: cover;
  }

  .dropdown-menu {
    padding: 0;
  }

  ul {
    padding: 0 20px !important;
  }
}

@keyframes moveInButton {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.btn-req-feature,
.btn-req-feature:link,
.btn-req-feature:visited {
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  width: fit-content;
  border-radius: 30px;
  transition: all 0.2s;
  position: relative;
  border: none;
  background-color: white;
  color: #2caf82;
  font-weight: 600;
  padding: 12px 25px;
  height: fit-content;
  text-align: center;
  cursor: pointer;
  animation: moveInButton 0.5s ease-out 1s;
  animation-fill-mode: backwards;
  line-height: 1;
  font-size: 14px;
}

.btn-req-feature:hover,
.btn-req-feature:focus {
  outline: none;
  transform: translateY(-2px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.btn-req-feature:hover::after,
.btn-req-feature:focus::after {
  transform: scaleY(1.3) scaleX(1.6);
  opacity: 0;
  background-color: white;
}

.btn-req-feature::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.table-stickyRow2 {
  tr > th:nth-child(1):not(.td-grouped) > span {
    width: 137px;
  }

  tr > th:nth-child(2):not(.td-grouped) {
    position: sticky;
    left: 163px;
    // z-index: 999 !important;
    z-index: 102 !important;
    border: 1px solid #cecece;
    transform: translate(-2.1px, -2.1px);
  }
  tr.sticky-subheaders > th:nth-child(2):not(.td-grouped) {
    position: sticky;
    left: 165px;
    // z-index: 998 !important;
  }
  tr > td:nth-child(2):not(.td-grouped) {
    position: sticky;
    left: 165px;
    // z-index: 998 !important;
    background-color: #f4faff;
    border: 1px solid #cecece !important;
    transform: translateX(-2.1px);
  }

  tr:nth-child(1)
    > th:nth-child(2):not(.td-grouped):not(.primary-col-data):not(.primary-col-data-alt):after,
  tr
    > td:nth-child(2):not(.td-grouped):not(.primary-col-data):not(.primary-col-data-alt)::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background: lighten(#455a64, 50%);
    transform: translateX(1px);
  }

  .total-row {
    td:nth-child(2) {
      z-index: 100 !important;
    }
  }
}

.accordion-wrapper {
  border-radius: 10px 10px 0px 0px;
  background-color: #f1ffff;
  border-bottom: 2px solid #50ffff;

  .heading {
    font-size: 25px;
    font-weight: 300;
    color: #183b56;
  }
  .accordion-title {
    font-size: 32px;
    font-weight: 700;
  }
  .accordion {
    background-color: #f3faf7;
  }

  .heading-flex {
    cursor: pointer;
  }
  .desc {
    font-size: 16px;
    font-weight: 500;
  }
}

.slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .slider-main {
    display: flex;
    align-items: center;
    justify-content: center;
    .slider {
      width: 200px;
    }
    .slider-text {
      font-size: 18px;
      font-family: "Roboto";
    }
  }
}

.dd-filter-text {
  min-width: 200px;
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  padding-left: 5px;
  padding-bottom: 3px;
}

.dd-filter-item {
  display: flex;
  align-items: flex-start;
  min-width: 250px;
  input[type="checkbox"] {
    position: relative;
    top: 7px;
  }
  .form-control {
    width: 95%;
    padding: 5px 10px;
  }

  .form-control:focus {
    outline: none;
    box-shadow: none;
  }
}

.dd-filter-items {
  max-height: 200px;
  overflow: auto;
  margin-left: 9px;
}

.range-min-max {
  cursor: pointer;
  color: darken(#04a9f5, 8.5%);
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0;

  input {
    display: none;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.pricing {
  background-color: #f5f5f5;

  .pricing-deatails {
    color: #2caf82;
    font-weight: 600;
    font-size: 18px;
  }
  .price span {
    font-size: 30px;
    font-weight: 700;
    margin-right: 10px;
  }
  .price-deatils {
    font-size: 20px;
    font-weight: 700;
  }
  .pricing-grid {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
  }
  .color {
    color: #2caf82;
  }
  .btn-withoutselect .fa-arrow-right {
    color: rgb(37, 34, 34);
  }
  .btn-withselect .fa-arrow-right {
    color: rgb(255, 255, 255);
  }
  .btn-withoutselect {
    background-color: transparent;
    padding: 10px 18px;
    font-weight: 500;
    border: 1px solid #5a7184;
    outline: none;
    border-radius: 25px;
  }
  .pricing-grid-item {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    justify-content: space-between;
    min-width: 400px;
  }
  .btn-withselect {
    background-color: #2caf82;
    padding: 10px 18px;
    color: white;
    outline: none;
    border: none;
    font-weight: 500;
    border: 1px solid none;
    border-radius: 25px;
  }
  @media (max-width: 1000px) {
    .pricing-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: 574px) {
    .pricing-grid {
      grid-template-columns: 1fr;
    }
  }

  .pricing-header-title {
    font-size: 35px;
    font-weight: 700;
  }
  .pricing-header-title span {
    color: #36b37e;
  }
  .form-check .form-check-input {
    float: none;
    font-size: 18px;
  }
  .form-check .form-check-input:focus {
    box-shadow: none;
  }
  .form-check-input:checked {
    background-color: #36b37e;
    border-color: #36b37e;
    background-position: left center;
  }
  .package-title {
    color: #36b37e;
    font-weight: 700;
    font-size: 35px;
  }

  .border-green {
    border: 1px solid #36b37e;
  }
  .monthly-color {
    color: gray;
    font-weight: 700;
  }
  .monthly {
    color: black;
    font-weight: 700;
  }
  .price {
    font-weight: 700;
  }
}

.custom-chart-outer {
  &:hover .custom-chart-pagination {
    opacity: 1 !important;
  }

  .pagination-wrapper {
    position: absolute;
    bottom: 25px;
    right: 10px;
    z-index: 999;
  }

  .custom-chart-pagination {
    opacity: 0;
    float: right;
    position: sticky;
    bottom: 0;
    right: 0;
    z-index: 100;
    transition: all 1s;
    margin: 0;
    .page-item .page-link {
      background-color: #bec4d5 !important;
      color: white;
    }

    .page-item .page-link:focus {
      box-shadow: none;
    }

    .page-item.active .page-link {
      background-color: lighten(#1dc4e9, 40%) !important;
      color: black !important;
      border-color: lighten(#1dc4e9, 40%) !important;
    }

    .page-item.disabled .page-link {
      background-color: lighten(#3f4d67, 10%);
      color: white;
    }
    .page-item.active.disabled .page-link {
      background-color: #1dc4e9;
      color: white;
    }
  }
  .pagination-display-none {
    display: none;
  }
}

.drillback-button {
  color: rgb(51, 51, 51);
  padding: 7px 14px;
  border: 1px solid rgb(204, 204, 204);
  background-color: rgb(247, 247, 247);
  position: absolute;
  z-index: 1000;
  border-radius: 2px;
  right: 0;
  top: 50px;
}

.drillback-button-for-table {
  color: rgb(51, 51, 51);
  padding: 7px 14px;
  border: 1px solid rgb(204, 204, 204);
  background-color: rgb(247, 247, 247);
  border-radius: 2px;
}

.selected-option-val {
  background-color: blue;
}

.sk-wrapper {
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
  .sk-head {
    align-self: flex-start;
  }
  .sk-body {
    align-self: center;
    max-height: 400px;
    overflow: hidden;
  }
  .sk-foot {
    align-self: flex-end;
  }
  .sk-local-filter {
    width: 100%;
    overflow: hidden;
  }

  .sk-table {
    width: 100%;
    overflow: hidden;
    .sk-row {
      width: 100%;
      white-space: nowrap;
      line-height: 1;
      .sk-col {
        width: 150px;
        padding: 5px;
        display: inline-block;
      }
    }
  }

  .sk-chart {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-align: center;
    .sk-column {
      display: inline-block;
      padding: 5px;
    }
  }

  .sk-bar {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    height: 100%;
    .sk-bar-val {
      padding: 5px;
    }
  }
  .sk-area {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    height: 100%;
    .sk-area-bar {
      display: inline-block;
      * {
        border-radius: 100px;
      }
    }
  }
  .sk-line {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    height: 100%;
    .sk-line-bar {
      display: inline-block;
      * {
        border-radius: 100px;
      }
    }
  }
}

.marquee {
  width: 450px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.widget-heading-center {
  text-align: center;
}

.widget-heading {
  font-size: 18px;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
    sans-serif;
  padding: 5px 10px;
  color: #455a64;
  line-height: 1.2;
}

#loading-modal {
  display: none;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  .svgContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  // background: rgba(255, 255, 255, 0.5) url("/loading.svg") 50% 50% no-repeat;
}

#error-modal {
  display: none;

  .modal-body p {
    margin: 0;
    padding: 20px 10px;
  }

  .modal-header,
  .modal-footer {
    padding: 10px;
  }
}

body.loading,
body.loading-all {
  // overflow: hidden;
}

body.loading #loading-modal,
body.loading-all #loading-modal {
  display: block;

  .svgContainer {
    display: block !important;
  }
}

@keyframes bg {
  0% {
    background: linear-gradient(
      to right bottom,
      rgba(54, 88, 80, 0.75),
      rgba(29, 196, 233, 0.75)
    );
  }
  50% {
    background: linear-gradient(
      to right bottom,
      rgba(29, 196, 233, 0.75),
      rgba(54, 88, 80, 0.75),
      rgba(29, 196, 233, 0.75)
    );
  }
  100% {
    background: linear-gradient(
      to right bottom,
      rgba(54, 88, 80, 0.75),
      rgba(29, 196, 233, 0.75)
    );
  }
}

#redirecting-modal {
  display: none;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation: bg 2s infinite;
  background: linear-gradient(
    to right bottom,
    rgba(29, 196, 233, 0.75),
    rgba(54, 88, 80, 0.75),
    rgba(29, 196, 233, 0.75)
  );
}

body.redirect #redirecting-modal {
  display: block;
}

.please-wait {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: white;
}

body.body-error {
  overflow: hidden;
}

body.body-error .modal,
body.loading .modal,
body.loading-all .modal {
  background: rgba(0, 0, 0, 0.5);
}

body.body-error #error-modal {
  display: block !important;
}

#error-modal {
  z-index: 1000000;
}

.canvasjs-chart-credit {
  display: none !important;
}

.header-account {
  cursor: pointer;
  padding: 3px 10px;
  border-radius: 2px;
  background-color: #3f4d67;
}

.span-dark {
  color: white;
  border: 1px solid #999 !important;
  background-color: #888 !important;
  padding: 6px 15px;
  font-size: 16px;
  box-shadow: 3px 2px 10px #aaa;
}

.span-outline-dark {
  color: #a9b7d0;
}

.span-outline-dark:hover {
  color: #1434a4;
  position: relative;
  top: -1px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
}

.account-d-flex {
  display: flex !important;
  flex-direction: row !important;
}

.no-noti {
  padding: 10px 20px !important;
  opacity: 0.4;
  font-size: 13px;
}

.widget-heading {
  font-size: 20px;
  padding: 3px 10px;
}

.widget-heading-independent {
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background-color: #fff;
  padding: 8px 10px;
}

.btn-link:hover,
.btn-link:focus,
.btn-link.focus {
  text-decoration: none;
}

.close {
  background-color: transparent;
  border: none;
}

.prop-text-area {
  min-height: 50vh !important;
}
.prop-pre {
  min-height: 30vh !important;
  max-height: 50vh !important;
}

.lg-icon {
  font-size: 50px;
}
.icon-warpper {
  position: relative;
}

.icon-left {
  cursor: pointer;
  position: absolute;
  left: 20px;
}

.hover-gray:hover {
  background-color: #ccc;
}

.icon-right {
  font-size: 30px;
  color: #0caee2;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 5px;
}

.icon-right-wrapper {
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 5px;
}

.icon-right:hover {
  color: #1434a4;
}

.icons-right {
  font-size: 30px;
  color: #0caee2;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 5px;
}

.icons-right i:hover {
  color: #1434a4;
}

.icons-right i.text-danger-light:hover {
  color: rgb(255, 0, 0) !important;
}

.text-danger-light {
  color: rgba(255, 0, 0, 0.7);
}

.right-text-rotated {
  font-size: 20px;
  color: rgba(255, 0, 0, 0.6);
  position: absolute;
  top: 40%;
  right: 20px;
  transform: rotate(-10deg);
  padding: 5px 15px;
  border: 2px solid rgba(255, 0, 0, 0.6);
  z-index: 100;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.right-text-rotated-blue {
  font-size: 20px;
  color: rgba(0, 0, 255, 0.6);
  position: absolute;
  top: 40%;
  right: 20px;
  transform: rotate(-10deg);
  padding: 5px 15px;
  border: 2px solid rgba(0, 0, 255, 0.6);
  z-index: 100;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.prop-text-area {
  min-height: 50vh;
}
.prop-pre {
  min-height: 30vh;
  max-height: 50vh;
}

.WordClouds {
  height: 544px !important;
}

.card-body .input-group > .form-control {
  padding: 7px 20px;
}

.link-blue {
  color: darken(#04a9f5, 8.5%) !important;
}

.link-white {
  color: #fff !important;
}

.tc {
  &_section_title {
    margin-bottom: 0.4rem;
    font-size: 1.8rem;
    font-weight: 600;
  }
  &_block {
    margin-bottom: 0.2rem;
  }
  &_subtitle {
    margin-bottom: 0.3rem;
    font-size: 1.25rem;
    font-weight: 600;
  }
  &_para {
    font-size: 1.1rem;
  }
  &_bold {
    font-weight: 600;
  }
  &_points > li {
    list-style-type: circle;
    font-size: 1.005rem;
  }
  &_cp {
    cursor: pointer;
  }
}

::selection {
  background-color: rgba(76, 175, 80, 0.6);
  color: white;
}

.color-black {
  color: black !important;
}

.color-less-black {
  color: #455a64 !important;
}

.f-1rem {
  font-size: 1rem;
}

.f-24px {
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: 300;
}

f-16px {
  font-size: 16px;
  font-weight: 300;
}

.initCap {
  text-transform: capitalize;
}

.z-1000 {
  z-index: 1000;
}

.machine {
  width: 60vmin;
  fill: #8bc53f;

  .black-fill {
    fill: #010101;
  }

  .small-shadow,
  .medium-shadow,
  .large-shadow {
    fill: rgba(0, 0, 0, 0.05);
  }

  .small {
    -webkit-animation: counter-rotation 2.5s infinite linear;
    -moz-animation: counter-rotation 2.5s infinite linear;
    -o-animation: counter-rotation 2.5s infinite linear;
    animation: counter-rotation 2.5s infinite linear;
    -webkit-transform-origin: 100.136px 225.345px;
    -ms-transform-origin: 100.136px 225.345px;
    transform-origin: 100.136px 225.345px;
  }

  .small-shadow {
    -webkit-animation: counter-rotation 2.5s infinite linear;
    -moz-animation: counter-rotation 2.5s infinite linear;
    -o-animation: counter-rotation 2.5s infinite linear;
    animation: counter-rotation 2.5s infinite linear;
    -webkit-transform-origin: 110.136px 235.345px;
    -ms-transform-origin: 110.136px 235.345px;
    transform-origin: 110.136px 235.345px;
  }

  .medium {
    -webkit-animation: rotation 3.75s infinite linear;
    -moz-animation: rotation 3.75s infinite linear;
    -o-animation: rotation 3.75s infinite linear;
    animation: rotation 3.75s infinite linear;
    -webkit-transform-origin: 254.675px 379.447px;
    -ms-transform-origin: 254.675px 379.447px;
    transform-origin: 254.675px 379.447px;
  }

  .medium-shadow {
    -webkit-animation: rotation 3.75s infinite linear;
    -moz-animation: rotation 3.75s infinite linear;
    -o-animation: rotation 3.75s infinite linear;
    animation: rotation 3.75s infinite linear;
    -webkit-transform-origin: 264.675px 389.447px;
    -ms-transform-origin: 264.675px 389.447px;
    transform-origin: 264.675px 389.447px;
  }

  .large {
    -webkit-animation: counter-rotation 5s infinite linear;
    -moz-animation: counter-rotation 5s infinite linear;
    -o-animation: counter-rotation 5s infinite linear;
    animation: counter-rotation 5s infinite linear;
    -webkit-transform-origin: 461.37px 173.694px;
    -ms-transform-origin: 461.37px 173.694px;
    transform-origin: 461.37px 173.694px;
  }

  .large-shadow {
    -webkit-animation: counter-rotation 5s infinite linear;
    -moz-animation: counter-rotation 5s infinite linear;
    -o-animation: counter-rotation 5s infinite linear;
    animation: counter-rotation 5s infinite linear;
    -webkit-transform-origin: 471.37px 183.694px;
    -ms-transform-origin: 471.37px 183.694px;
    transform-origin: 471.37px 183.694px;
  }

  @-webkit-keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
  @-moz-keyframes rotation {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(359deg);
    }
  }
  @-o-keyframes rotation {
    from {
      -o-transform: rotate(0deg);
    }
    to {
      -o-transform: rotate(359deg);
    }
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @-webkit-keyframes counter-rotation {
    from {
      -webkit-transform: rotate(359deg);
    }
    to {
      -webkit-transform: rotate(0deg);
    }
  }
  @-moz-keyframes counter-rotation {
    from {
      -moz-transform: rotate(359deg);
    }
    to {
      -moz-transform: rotate(0deg);
    }
  }
  @-o-keyframes counter-rotation {
    from {
      -o-transform: rotate(359deg);
    }
    to {
      -o-transform: rotate(0deg);
    }
  }
  @keyframes counter-rotation {
    from {
      transform: rotate(359deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
}

.modal {
  backdrop-filter: blur(0.5px);
}

.modal-open .modal {
  backdrop-filter: blur(3px);
}

body > iframe {
  height: 0 !important;
  width: 0 !important;
}

.mh-300 {
  max-height: 300px;
}

.mw-300 {
  max-width: 300px;
}

.display-inline-block {
  display: inline-block;
}

.font-26px {
  font-size: 26px;
}

.fade-na {
  opacity: 0.5;
}

.nav-tabs .nav-link {
  color: black;
}

.download-icon-right {
  position: absolute;
  right: 0px;
  top: 10px;
  color: darken(#04a9f5, 8.5%);
  cursor: pointer;
}

.icon-blue {
  color: darken(#04a9f5, 8.5%);
  cursor: pointer;
}

.hide-caret {
  &::after {
    display: none !important;
  }

  .header-account {
    position: relative;
    padding-right: 25px;
  }

  .header-account::after {
    content: "\e842";
    font-family: "feather";
    font-size: 15px;
    border: none;
    position: absolute;
    right: 5px;
    top: 2px;
  }
}

.schedult-task-grid {
  display: grid !important;
  grid-template-columns: 2.5fr 1fr;
  background: transparent;
  grid-gap: 10px;
}

.grid-1-1fr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

.align-self-end {
  align-self: baseline;
}

.grid-1-1-1fr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
}

.cursor-pointer {
  cursor: pointer;
}
